import axios from '@/axios';

class WorkflowStageService {
    index(workflowId) {
        return axios.get(`/settings/workflows/${workflowId}/stages`);
    }
    create(workflowId, data) {
        return axios.post(`/settings/workflows/${workflowId}/stages`, data);
    }
    update(workflowId, stageId, data) {
        return axios.put(`/settings/workflows/${workflowId}/stages/${stageId}`, data);
    }
    destroy(workflowId, stageId) {
        return axios.delete(`/settings/workflows/${workflowId}/stages/${stageId}`);
    }
}

export const workflowStageService = new WorkflowStageService();