import axios from '@/axios';

class WorkflowService {
    index(query) {
        return axios.get(`/settings/workflows`, {
            params: query
        });
    }
    create(data) {
        return axios.post(`/settings/workflows`, data);
    }
    show(id) {
        return axios.get(`/settings/workflows/${id}`);
    }
    update(id, data) {
        return axios.put(`/settings/workflows/${id}`, data);
    }
    destroy(id) {
        return axios.delete(`/settings/workflows/${id}`);
    }
    users() {
        return axios.get(`/settings/workflows/users`);
    }
}

export const workflowService = new WorkflowService();