<template>
    <div class="modal fade invite-people show" id="invitepeople" tabindex="-1" role="dialog"
         aria-labelledby="addcustomfield" style="display: block; padding-right: 17px;">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <button @click="hideAddStageComponent" type="button" class="close" data-dismiss="modal"
                        aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div class="modal-body">
                    <h3>{{ $t('Create Workflow Stage') }}</h3>

                    <form @submit.prevent="addWorkflow" class="invite-form">
                        <div class="form-group">
                            <label>{{ $t('Title') }}</label>
                            <input v-model="stage.title" type="text" class="form-control" required>
                            <div v-if="errors['title']" v-for="error in errors['title']" :key="error"
                                 class="text-danger">{{ error }}
                            </div>
                        </div>

                        <div class="form-group">
                            <button class="btn btn-primary">{{ $t('ADD') }}</button>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {workflowStageService} from '@/services/settings/workflowStageService'

export default {
    name: "AddWorkflowStage",
    props: ["workflow"],
    data() {
        return {
            stage: {},


        }
    },
    emits: ['hideComponent'],
    mounted() {
        this.stage.workflow_id = this.$props.workflow.id;
    },
    methods: {
        hideComponent() {
            this.$emit('hideAddStageComponent')
        },
        addWorkflow() {
            workflowStageService.create(this.workflow.id, this.stage).then(response => {
                this.$emit('hideAddStageComponent')
                this.$emit('reload')
            })
        }
    }
}
</script>

<style scoped>

</style>