<template>
    <h4>{{ $t('Workflows') }}</h4>

    <a @click.prevent="getWorkflows" href="#" class="add-field">% {{ $t('REFRESH') }}</a><br>

    <div class="input-group">
        <input v-model.trim="search" @input="debounceGetWorkflows" type="text" name="search"
               :placeholder="$t('Search')">
    </div>

    <div class="row">

        <div class="col-xs-12 col-md-4">
            <ul class="list-group">
                <li v-for="workflow in workflows" :key="workflow.id" class="list-group-item"
                    @click="setActiveWorkflow(workflow)">
                    {{ workflow.title }}
                </li>
            </ul>
            <a @click.prevent="showAddComponent=true" href="#" class="btn btn-primary">+ {{ $t('ADD') }}</a>
            <add-workflow-component v-if="showAddComponent" @hideComponent="showAddComponent=false"
                                    @reload="getWorkflows"/>
        </div>

        <div class="col-xs-12 col-md-4">
            <div v-if="stages.length">
                <ul class="list-group">
                    <li v-for="stage in stages" :key="stage.id" class="list-group-item">
                        {{ stage.title }}
                    </li>
                </ul>
                <a @click.prevent="showAddStageComponent=true" href="#" class="btn btn-primary">+ {{ $t('ADD') }}</a>
                <add-workflow-stage-component v-if="showAddStageComponent"
                                              @hideAddStageComponent="showAddStageComponent=false" @reload="getStages"
                                              :workflow="activeWorkflow"/>
            </div>
        </div>

        <div class="col-xs-12 col-md-4">
            <div v-if="stages.length">
                <ul class="list-group">
                    <li v-for="stage in stages" :key="stage.id" class="list-group-item">
                        <select class="form-control" v-model="stage.responsible_id">
                            <option v-for="user in users" :value="user.id">{{ user.profile.first_name }}
                                {{ user.profile.last_name }}
                            </option>
                        </select>
                    </li>
                </ul>
            </div>
        </div>

    </div>


</template>

<script>
import _ from "lodash";
import {workflowService} from '@/services/settings/workflowService'
import {workflowStageService} from '@/services/settings/workflowStageService'
import AddWorkflowComponent from "@/views/settings/workflows/AddWorkflow";
import AddWorkflowStageComponent from "@/views/settings/workflows/AddWorkflowStage";

export default {
    name: "ViewAllWorkflows",
    data() {
        return {
            workflows: [],
            activeWorkflow: null,
            stages: [],
            users: [],

            search: '',
            page: '',
            showAddComponent: false,
            showAddStageComponent: false,
        }
    },
    mounted() {
        this.getUsers()
        this.getWorkflows()
    },
    methods: {
        getWorkflows() {
            workflowService.index({
                "q": this.search,
                "page": this.page
            }).then(response => {
                this.workflows = response.data.data
            })
        },
        getStages() {
            workflowStageService.index(this.activeWorkflow.id).then(response => {
                this.stages = response.data.data
            })
        },
        getUsers() {
            workflowService.users().then(response => {
                this.users = response.data.data
            })
        },
        setActiveWorkflow(workflow) {
            this.activeWorkflow = workflow;
            this.getStages();
        },
        debounceGetWorkflows: _.debounce(function () {
            this.getWorkflows()
        }, 1000)
    },
    components: {
        AddWorkflowComponent,
        AddWorkflowStageComponent
    }
}
</script>