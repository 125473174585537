<template>
    <div class="modal fade invite-people show" id="invitepeople" tabindex="-1" role="dialog"
         aria-labelledby="addcustomfield" style="display: block; padding-right: 17px;">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <button @click="hideComponent" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <div class="modal-body">
                    <h3>{{ $t('Create Workflow') }}</h3>

                    <form @submit.prevent="addWorkflow" class="invite-form">
                        <div class="form-group">
                            <label>{{ $t('Title') }}</label>
                            <input v-model="workflow.title" type="text" class="form-control" required>
                            <div v-if="errors['title']" v-for="error in errors['title']" :key="error"
                                 class="text-danger">{{ error }}
                            </div>
                        </div>

                        <div class="form-group">
                            <label>{{ $t('Description') }}</label>
                            <textarea v-model="workflow.description" type="text" class="form-control"
                                      required></textarea>
                            <div v-if="errors['description']" v-for="error in errors['description']" :key="error"
                                 class="text-danger">{{ error }}
                            </div>
                        </div>

                        <div class="form-group">
                            <button class="btn btn-primary">{{ $t('ADD') }}</button>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {workflowService} from '@/services/settings/workflowService'

export default {
    name: "AddWorkflow",
    data() {
        return {
            workflow: {},


        }
    },
    emits: ['hideComponent'],
    methods: {
        hideComponent() {
            this.$emit('hideComponent')
        },
        addWorkflow() {
            workflowService.create(this.workflow).then(response => {
                this.$emit('hideComponent')
                this.$emit('reload')
            })
        }
    }
}
</script>

<style scoped>

</style>